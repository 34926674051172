var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"product-mapping"}},[_c('v-data-table',{staticClass:"elevation-1 mb-5",attrs:{"headers":_vm.header,"items":_vm.prototypes,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage,"server-items-length":_vm.itemsLength,"loading":_vm.loading,"height":530,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.optionsUpdated()},"update:sort-by":function($event){return _vm.optionsUpdated()},"update:sort-desc":function($event){return _vm.optionsUpdated()}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"capitalize"},[_vm._v(" Product Mapping ")])]),_c('v-spacer'),_c('search-box',{on:{"on-search":function($event){return _vm.search()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"item.order.orderId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("paddingNumber")(item.order.orderId))+" ")]}},{key:"item.targetDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateCompute(item.order.targetDate))+" ")]}},{key:"item.map",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
          name: 'ProductMappingDetail',
          params: { id: item.id }
        },"icon":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-book-arrow-left-outline ")])],1)]}}],null,true)}),(_vm.paginationEnable)?_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.pageCount,"disabled":_vm.loading},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }